document.addEventListener('musickitconfigured', function () {

  console.log('musickitconfigured event triggered');
})

document.addEventListener('musickitloaded', function () {
  // MusicKit global is now defined
  MusicKit.configure({
    developerToken: 'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkoyWUdSSjdCODcifQ.eyJpYXQiOjE2OTc0NjQyMjcsImV4cCI6MTcxMzAxNjIyNywiaXNzIjoiU1VMNUJUOFFBVCJ9.WHX0kb0KLUJO7y3zTV0WY7pMu65oEe3wFqztt_6IXBFLUwYmLYJ5tKakTJ9KHoiulJRqfhxmiQ7E5PDbZck4jg',
    app: {
      name: 'My Cool Web App',
      build: '1978.4.1'
    }
  });

  //console.log("MusicKit configured.")
  const event = new Event('musicKitConfigured');
  document.dispatchEvent(event)
});


